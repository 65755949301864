<template>
    <ul class="state-list">
        <li v-for="(item,index) in stateList"
            class="state-item"
            :class="currentState ==  item.id ? 'current' : ''"
            :key="item.id"
            @click="changeState(item)">
            <i class="circle" :class="circleClass(item.id)">{{item.label}}</i>
            <p class="sum-num">{{item.sumNum}}个</p>
        </li>
    </ul>
</template>

<script>
	export default {
		name:"room-state-list",
		props:{
			stateList:{
				type:Array,
				default:() => []
			},
			currentState:{
				type:Number,
				default:"-1"
			},
		},
		computed:{
			circleClass(){
				return function(state){
					state = parseInt(state,10)
					switch(state){
						case -1:
							return ''
							break
						case 1:
							return 'idle-room'
							break
						case 2:
							return 'guest-room'
							break
						case 3:
							return 'dirty-room'
							break
						case 4:
							return 'break-room'
							break
					}
				}
			},
		},
		methods:{
			changeState(item){
				this.$emit('changeState',item.id)
			}
		},
        mounted(){
			
		}
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .state-list{
        width:100vw;
        background:#FFFFFF;
        color:#666666;
        display:flex;
        z-index:2;
    }

    .state-item{
        flex:1;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        padding:20px 0;
        &.current{
            background:#EAEAEA;
            color:$high-light-color;
        }
    }

    .circle{
        width:64px;
        height:64px;
        line-height:64px;
        text-align:center;
        border-radius:50%;
        color:#FFFFFF;
        font-size:24px;
        background:$high-light-color;
    }


    .idle-room {
        background:#93A3B5;
    }

    .guest-room {
        background:#15bb44;
    }

    .dirty-room {
        background:#5b3cfc;
    }

    .break-room {
        background:#e13744;
    }

    .sum-num{
        color:#4D555E;
        font-size:22px;
    }

</style>